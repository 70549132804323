import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import * as React from "react"
import { useEffect, useRef } from "react"
import Battery from "../svgs/battery.svg"
import Wifi from "../svgs/wifi.svg"

const Layout = ({ location, children }) => {
  const rootPath = `${__PATH_PREFIX__}/`
  const isRootPath = location.pathname === rootPath
  const timeRef = useRef<HTMLSpanElement>(null)

  useEffect(() => {
    const evaluateTime = () => {
      const now = new Date()

      if (!timeRef.current) return

      const hours = "0" + now.getHours()
      const minutes = "0" + now.getMinutes()

      timeRef.current.innerText = `${hours.slice(-2)}:${minutes.slice(-2)}`
    }

    evaluateTime()

    setInterval(() => {
      evaluateTime()
    }, 1000)
  }, [])

  return (
    <div
      className="flex justify-center items-center w-full h-screen
      "
      data-is-root-path={isRootPath}
      style={{
        background:
          "linear-gradient(180deg, rgba(255,255,255,0) 160px, #f1eaea 40%, rgba(255,255,255,0) 100%)",
      }}
    >
      <div
        className="relative max-w-full w-full h-full p-0 rounded-[50px] flex justify-center items-center
        sm:max-w-[900px] sm:py-3
        "
      >
        <div
          className="flex flex-col relative overflow-hidden h-full w-full 
          sm:rounded-[40px]
        "
        >
          <StaticImage
            className="blur-sm !absolute w-[140%] h-[110%] top-1/2 left-[50%] -translate-x-1/2 -translate-y-1/2
              sm:w-[100%]
            "
            layout="fullWidth"
            formats={["auto", "webp", "avif"]}
            src="../images/bg-img.jpeg"
            alt=""
          />
          <div className="absolute top-0 bottom-0 left-0 right-0 bg-black opacity-25" />
          <div
            className="relative z-10 min-h-0 flex-grow flex flex-col items-center px-2
          sm:px-0
          "
          >
            <header
              className="absolute w-full flex justify-center items-center px-8 top-2 z-50 h-auto 
              sm:max-w-2xl sm:top-4 sm:h-12"
            >
              <h1 className="w-full h-full bg-kwonBlack text-white rounded-[2rem] flex justify-between items-center px-4 py-1">
                <span ref={timeRef}>11:12</span>
                <Link to="/" className="font-bold">
                  0307kwon.com
                </Link>
                <div className="flex gap-1">
                  <Wifi />
                  <Battery />
                </div>
              </h1>
            </header>
            <main
              id={"layoutMain"}
              className="h-full w-full pt-14 
              sm:pt-[5.25rem]"
            >
              {children}
            </main>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Layout
