import React from "react"
import Layout from "./src/components/layout"
import ContextModal from "./src/context/contextModal"
import { Script } from "gatsby"

export const wrapPageElement = ({ element, props }) => {
  return (
    <>
      <Layout {...props}>
        <ContextModal>{element}</ContextModal>
      </Layout>
      <Script
        async
        src="https://www.googletagmanager.com/gtag/js?id=G-QTR434VVBP"
      ></Script>
      <Script>
        {`window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag('js', new Date());

  gtag('config', 'G-QTR434VVBP');`}
      </Script>
    </>
  )
}
