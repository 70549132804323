import React, { ReactNode, useContext, useState } from "react"
import { isBrowser } from "../utils"

interface ModalControl {
  openModal: (contents: ReactNode) => void
  closeModal: () => void
}

const Context = React.createContext<ModalControl | null>(null)

interface Props {
  children: ReactNode
}

const ContextModal = ({ children }: Props) => {
  const [isContentsRendered, setIsContentsRendered] = useState(false)
  const [modalControl, setModalControl] = useState<{
    isOpen: boolean
    contents: ReactNode
  }>({
    isOpen: false,
    contents: undefined,
  })

  const openModal = (contents: ReactNode) => {
    setIsContentsRendered(true)
    setModalControl(prev => ({
      ...prev,
      contents,
      isOpen: true,
    }))
  }

  const closeModal = () => {
    setModalControl(prev => ({
      ...prev,
      isOpen: false,
    }))

    setTimeout(() => {
      if (!isBrowser()) return

      setIsContentsRendered(false)
    }, 300)
  }

  return (
    <Context.Provider
      value={{
        openModal,
        closeModal,
      }}
    >
      {children}
      <div
        className={`absolute top-0 left-0 right-0 bottom-0 pt-14 px-2 opacity-0 translate-y-full transition-all
          ${modalControl.isOpen ? "!translate-y-0 opacity-100" : ""}
          sm:pt-[5.25rem]
        `}
        style={{
          zIndex: 10,
        }}
      >
        {isContentsRendered && modalControl.contents}
      </div>
    </Context.Provider>
  )
}

export default ContextModal

export const useContextModal = () => {
  const context = useContext(Context)

  if (context === null) {
    throw new Error()
  }

  return context
}
